module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"mortgage.connectedrates.com","short_name":"mortgage.connectedrates.com","start_url":"/","version":"0.0.2","permissions":["gcm","storage","notifications"],"gcm_sender_id":"395966986145","gcm_user_visible_only":true,"display":"standalone","icon":"/Users/kevin/Documents/sc.mcr.com/src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e90bc6647097e8880fb6343f5946cbaa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P62KC9K","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"siteVertical":"other","siteName":"mortgage.connectedrates.com","defaultOffer":"","siteCompany":"Parent","siteBuyer":"Combined"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
